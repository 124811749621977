$play-button: url('../images/icons/icon-play.svg') no-repeat center center / 42px 42px;
$image-border-radius: 0;
$category-color: $color-primary;
$subtext-color: $color-secondary;
$description-color: $color-secondary;

.cc--article-card {
  @include mq($bp768max) {
    + .cc--article-card {
      @include margin(rem(30) null null);
    }
  }

  @include mq($bp768) {
    &:nth-of-type(3) ~ .cc--article-card {
      @include margin(rem(30) null null);
    }
  }

  @include mq($bp1024) {
    &:nth-of-type(3) ~ .cc--article-card {
      @include margin(rem(40) null null);
    }
  }

  a {
    display:block;
 }

  &.with-video {
    .f--image {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        background: $play-button;
        content: '';
      }
    }
  }

  .c--article-card {
    > a:hover + .text-container {
      .f--cta-title a {
        text-decoration: underline;
      }
    }
  }

  .f--image {
    @include margin(null null rem(16));

    @include mq($bp1024) {
      @include margin(null null rem(27));
    }

    img {
      border-radius: $image-border-radius;
    }
  }

  .f--cta-title {
    @include margin(null null rem(10));
    @include font-size(24, 26);

    @include mq($bp768) {
      @include font-size(20, 24);
    }

    @include mq($bp1024) {
      @include font-size(30, 30);
    }

    a {
      color: $color-sherpa-blue;
      text-decoration: none;

      @include hover {
        color: $color-sherpa-blue;
        text-decoration: underline;
      }
    }
  }

  .f--description {
    p {
      @include font-size(16, 23);
      color: $description-color;

      &:last-child {
        @include margin(null null 0);
      }
    }
  }

  .publication-link {
    @include margin(rem(15) null null);

    @include mq($bp768) {
      @include margin(rem(20) null null);
    }

    @include mq($bp1024) {
      @include margin(rem(22) null null);
    }

    a {
      @extend %font-fira-sans-extra-condensed-regular;
      @include font-size(16, 16);
      text-decoration: none;

      @include mq($bp768) {
        @include font-size(20, 24);
      }

      @include hover {
        text-decoration: underline;
      }

      svg {
        vertical-align: middle;
      }
    }
  }
  .usf-blog-images {
    .f--image {
      img {
        object-fit: cover;
        aspect-ratio: 16 / 9;
      }
    }
  }
}
