#cookiesjsr-wrapper {
    #cookiesjsr-click {
        position: fixed;
        bottom: 390px;
        right: 10px;
        width: 50px;
        height: 20px;
        cursor: pointer;
        z-index: 101;

        @include mq($bp768) {
            bottom: 122px;
        }

        svg {
            height: 27px;
            width: 27px;
        }
    }
    .cookiesjsr-banner {
        @include padding(rem(20) rem(30) rem(8) rem(30));
        background: $color-black;
        z-index: 100;
        height: 430px;

        @include mq($bp768) {
            height: 170px;
            flex-direction: column;
            align-items: flex-start;
        }
        @include mq($bp1024) {
            padding-left: 4.16667%;
        }
       
        .cookiesjsr-banner--info {
            padding-left: 0;
            padding-right: 0;
            position: relative;       
            top: 30px;
            overflow: unset;

            @include mq($bp768) {
                top: unset;
                width: 90%;
                position: relative;
                max-width: 800px;
                margin-bottom: 15px;
            }
            .cookiesjsr-banner--text {
                display: inline;
                margin-right: 5px;
            }
            .cookiesjsr-links.links--row {
                display: inline-block;
                @include font-size(16, 16);
                margin-top: 0;
            }
        }

        .cookiesjsr-banner--action {
            position: relative;       
            top: 30px;

            @include mq($bp768) {
                width: 80%;
                top: -10px;
                flex-direction: row;
            }
        }

        .cookiesjsr-btn {
            width: 100%;
            @include margin(rem(10) rem(0));
            @include font-size(18, 18);
            min-height: 3.125rem;

            padding-right: 2rem;
            padding-left: 2rem;

            @include mq($bp768) {
                width: 12.5rem;
                @include margin(rem(10) rem(20) rem(10) rem(0));
            }

            &:hover {
                transform: none;
                border-width: 0.3125rem
            }

            &.cookiesjsr-settings {
                @extend %font-fira-sans-bold;    
                color: white;
                background-color: $color-black;
                border-color: $color-supernova;
                
            }
            &.denyAll, 
            &.allowAll {
                @extend %font-fira-sans-bold;
                color: $color-white;
                background: none;
                border-color: $color-supernova;    
                
            }
        }
    }
    .cookiesjsr-layer {
        max-height: 700px;

        .cookiesjsr-service-group.active {
            .cookiesjsr-service-group--tab,
            .cookiesjsr-service-group--content {
                background-color: $color-black;
            }
        }

        .cookiesjsr-service-group--intro {
            @include padding(rem(30));
        }

        .cookiesjsr-layer--footer {
            height: 100px;

            .cookiesjsr-layer--actions {
                padding-left: 20px;
                padding-right: 10px;
            }
        }

        .cookiesjsr-btn {
            width: 100%;
            @include font-size(16, 16);
            min-height: 3.125rem;

            padding-right: 2rem;
            padding-left: 2rem;

            @include mq($bp768) {
                width: 12.5rem;
                @include margin(rem(10));
            }

            &:hover {
                transform: none;
                border-width: 0.3125rem
            }

            &.invert {
                @extend %font-fira-sans-bold;    
                color: $color-black;
                background-color: $color-white;
                border-color: $color-supernova;
                
            
                &.save {
                    color: $color-white;
                    background-color: $color-black;
                    border-color: $color-supernova;    
                } 
            }
        }
        .cookiesjsr-service--description h3 {
            font-weight: 700;
        }
        .cookiesjsr-service--always-on {
            color: #02B8A0;
            @extend %font-fira-sans-bold;
            top: -12px;
            position: relative;
        }
    }
}