$play-button: url('../images/icons/icon-play.svg') no-repeat center center / 42px 42px;
$radius: 0;

.cc--rich-text-and-image {
  @include padding(null null rem(30));
  @include margin(null null rem(30));
  border-bottom: 1px solid $color-border;

  &.with-video {
    .f--image {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        background: $play-button;
        content: '';
      }
    }
  }

  .c--rich-text-and-image {
    @include mq($bp768) {
      display: flex;
    }
  }

  .text-container {
    @include mq($bp768) {
      @include padding(null cols(2, 28) null null);
      flex: 0 0 cols(17, 28);
      width: cols(17, 28);
    }

    @include mq($bp1024) {
      @include padding(null cols(2, 28) null null);
      flex: 0 0 cols(16, 28);
      width: cols(16, 28);
    }
  }

  .image-container {
    @include margin(rem(15) null null);

    @include mq($bp768) {
      @include margin(rem(0) null null);
      flex: 0 0 cols(11, 28);
      width: cols(11, 28);
    }

    @include mq($bp1024) {
      flex: 0 0 cols(12, 28);
      width: cols(12, 28);
    }

    img {
      border-radius: $radius;
    }
  }

  .f--cta-title {
    @include margin(null null rem(14));

    a {
      @include font-size(22, 26);

      @include mq($bp768) {
        @include font-size(20, 23);
      }

      @include mq($bp1024) {
        @include font-size(26, 32);
      }

      @include hover {
        color: $color-secondary;
        text-decoration: underline;
      }
    }
  }
}
