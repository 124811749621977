$radius: 0;

.cc--rich-text {
  @include maxwidth;
  @include clearfix;
}

%rich-text {
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include margin(null null rem(15));
    color: $color-sherpa-blue;

    @include mq($bp768) {
      @include margin(null null rem(20))
    }

    a {
      text-decoration: underline;
    }
  }

  figure,
  img {
    @include margin(null null rem(10));

    @include mq($bp768) {
      @include margin(null null rem(15))
    }

    &.align-center {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;

      article, img {
        width: 100%;
      }
    }
  }

  .align-left {
    @include mq($bp768) {
      @include margin(null cols(2, 40) null null);
      float: left;
    }

    @include mq($bp1024) {
      @include margin(null cols(2, 28) null null);
    }
  }

  .align-right {
    @include mq($bp768) {
      @include margin(null null null cols(2, 40));
      float: right;
    }

    @include mq($bp1024) {
      @include margin(null null null cols(2, 28));
    }
  }

  article {
    &.align-center {
      width: 100%;

      img {
        width: 100%;
      }
    }
    .embedded-image {
      img {
        width:auto;
      }
    }
  }

  img {
    @include margin(null null rem(16));
    display: block;
    width: auto;
    max-width: 100%;
    border-radius: $radius;
  }

  figure {
    @include margin(null null rem(35));

    @include mq($bp768) {
      @include margin(null null rem(70));
    }

    figcaption {
      @extend %font-fira-sans-extra-condensed-regular;
      @include font-size(14, 18);
      color: $color-black;
      font-style: normal;
      text-align: left;
      text-transform: none;

      a {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
      }
      strong {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    @include font-size(14, 18);
    color: $color-black;
    word-break: break-word;

    @include mq($bp768) {
      @include font-size(16, 24);
    }

    &.cite {
      font-family: 'Fira Sans Extra Condensed', sans-serif;
      font-weight: 700;   
      font-size: 1rem;
      line-height: 1.2;
      text-transform: uppercase;    
      margin-left: 40%;
      padding-bottom: 2.25rem;
      
      @include mq($bp768) {
        font-size: 1.25rem;
        margin-top: -2rem;
        margin-left: 50%;
      }
      @include mq($bp1024) {
        margin-left: 60%;
      }

      span.cite-title {
        font-family: 'IBM Plex Serif', serif;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.625;
        text-transform: none;
        display: block;

        @include mq($bp768) {
          font-size: 1rem;
        }
      }
    }
  }

  a {
    @include font-size(14, 18);
    color: $color-sherpa-blue;

    @include mq($bp768) {
      @include font-size(16, 24);
    }

    @include hover {
      color: $color-sherpa-blue;
      text-decoration: none;
    }

    &.acalog {
      @include margin(null null rem(10));
      display: inline-block;
    }

    strong,
    b {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .note {
    @extend %font-fira-sans-extra-condensed-regular;
    @include font-size(13,18);

    a {
      font-size: inherit!important;
      font-family: inherit;
    }
    strong {
      font-size: inherit;
      font-family: inherit;
    }
  }

  .clear {
    clear: both;
  } 

  .clearfix {  

    &:after {
        clear: both;
        content: “”;
        display: block;
        height: 0;
        line-height: 0;
        visibility: hidden;
    }
  }

  .cta-link {
    @include button($secondary: true);
    @include margin(rem(20) null null);
    
    @include mq($bp768max) {
      + .cta-link {
        @include margin(rem(20) null null);
        
      }
    }

    @include mq($bp768) {
      @include margin(rem(0) rem(15) rem(25) null);
    }

    @include mq($bp1024) {
      @include margin(null rem(20) rem(25) null);
    }
  }

  strong,
  b {
    @extend %font-ibm-plex-serif-bold;
    @include font-size(16, 26);
  }

  ul,
  ol {
    @include padding(0 null rem(30) 0);

    strong,
    b {
      font-size: inherit;
      line-height: inherit;
    }

    ol,
    ul {
      @include padding(rem(24) null 0);
    }

    li {
      @include font-size(14, 18);
      @include padding(null null null rem(25));
      @include margin(null null rem(24));
      position: relative;

      @include mq($bp768) {
        @include padding(null null null rem(25));
        @include font-size(16, 24);
      }

      a {
        color: $color-sherpa-blue;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;

        @include hover {
          color: $color-sherpa-blue;
        }
      }
    }
  }

  ul {
    list-style: none;

    > li {
      &::before {
        display: block;
        position: absolute;
        top: rem(6);
        left: 0;
        width: rem(8);
        height: rem(8);
        line-height: rem(30);
        border-radius: 50%;
        background: $color-supernova;
        content: '';

        @include mq($bp768) {
          top: rem(8);
        }
      }
    }
  }

  ol {
    @include padding(null null null rem(18));

    > li {
      @include padding(null null null rem(10));

      &::marker {
        color: $color-supernova;
        font-weight: 600;
      }
    }
  }
  
  ul.twoColumnList {
    @include mq($bp768) {
      column-count: 2;
      column-gap: 2.5rem;
      }
  }
  ul.twoColumnListNoBullet {
    @include mq($bp768) {
      column-count: 2;
      column-gap: 2.5rem; 
    }
    > li {
      padding-left: 0;

      &::before {
        content: none;
      }
    }
  }

  .text-align-center {
    text-align: center;
  }
  .video-embed {
    aspect-ratio: 16 / 9;
    line-height: 1.2858;

    iframe {
      @include margin(null null rem(13));
      max-width: 100%;
      width: 100%;
      height: 100%;
    }

    @supports not (aspect-ratio: 16 / 9) {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  blockquote {
    @include margin(rem(40) 0 0);
    @include padding(null null rem(24));
    position: relative;
    clear: both;

    @include mq($bp768) {
      @include margin(rem(64) null null);
      @include padding(null null rem(60));
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      width: rem(36);
      height: rem(33);
      background: $color-white url('../images/icons-usf/quote.svg') no-repeat center;
      background-size: cover;
      content: '';

      @include mq($bp768) {
        width: rem(75);
        height: rem(67);
      }
    }

    &.tiktok-embed {
      &::before {
        display: none;
      }
    }

    &,
    p {
      @extend %font-fira-sans-extra-condensed-regular;
      @include margin(null null 0);
      @include font-size(20, 24);
      color: $color-sherpa-blue;

      @include mq($bp768) {
        @include font-size(36, 40);
      }

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
    }

    p {
      @include padding(null null null rem(20));
      @include margin(null null null rem(56));
      border-left: rem(2) solid $color-supernova;

      @include mq($bp768) {
        @include padding(null null null rem(48));
        @include margin(null null null rem(125));
      }
    }
  }

  table:not(.gsc-above-wrapper-area-container) {
    @include margin(null null rem(20));
    width: 100%;
    border: 0;
    border-spacing: 0;
    overflow: auto;
    word-break: normal;
    table-layout: fixed;

    thead {
      background-color: $color-grey-light;

      th {
        @extend %font-fira-sans-extra-condensed-semibold;
        @include padding(rem(15) rem(25));
        @include font-size(20, 20);
        border: 0;
        color: $color-black;
        text-align: left;

        &:not(:last-child) {
          border-right: rem(1) solid $color-gainsboro;
        }

        &.green {
          background-color: $color-sherpa-blue;
          color: $color-white;
        }
        &.bigger {
          @include font-size(24, 24);
        }
        &.center {
          text-align: center;
        }
      }
    }

    tbody {
      th,
      td {
        @extend %font-fira-sans-extra-condensed-regular;
        @include padding(rem(20) rem(25));
        @include font-size(16, 20);
        border: 0;
        color: $color-black;
        font-weight: normal;

        &:not(:last-child) {
          border-right: rem(1) solid $color-gainsboro;
        }

        &.altColor1:not(:last-child),
        &.altColor2:not(:last-child) {
          border-right: rem(1) solid $color-line-sherpa-blue;
        }

        @include mq($bp1024) {
          @include font-size(20, 20);
        }

        strong {
          @extend %font-fira-sans-extra-condensed-bold;
          @include font-size(16, 20);

          @include mq($bp1024) {
            @include font-size(20, 20);
          }
        }

        a {
          @extend %font-fira-sans-extra-condensed-regular;
          @include font-size(16, 20);
          color: $color-sherpa-blue;

          @include mq($bp1024) {
            @include font-size(20, 20);
          }
        }
      }

      tr {
        border-bottom: rem(1) solid $color-border;

        &:nth-child(odd) {
          background: $color-bright-grey;
        }

        &:first-child {
          border-top: rem(1) solid $color-border;

          td,
          th {
            border-top: rem(1) solid $color-border;
          }
          td.altColor1 {
            border-top: rem(1) solid $color-sherpa-blue;
          }
        }

        &:last-child {
          td,
          th {
            border-bottom: rem(1) solid $color-border;
            width: 100%;
          }
        }
      }

      td {
        &.altColor1 {
          background-color: $color-light-sherpa-blue;
        }
        &.altColor2 {          
          background-color: $color-very-light-sherpa-blue;
        }
      }
    }

    tr {
      background: none;
    }
  }

  .resp-table {
    @include margin(null null rem(20));
    width: 100%;
    overflow-x: auto;

    table {
      width: auto;
    }
  }
}

.cc--profile-headers,
.cc--accordions {
  h3 {
    @include font-size(26, 30);
  }
  h4 {
    @include font-size(24, 28);

    @include mq($bp768) {
      @include font-size(23, 27);
    }
  }
  h5 {
    @include font-size(20, 24);
    
    @include mq($bp768) {
      @include font-size(22, 26);
    }
  }
  h6 {
    @include font-size(18, 22);
    
    @include mq($bp768) {
      @include font-size(20, 24);
    }
  }
}

.f--wysiwyg {
  .magazine-callout-wrapper, 
  .magazine-callout-wrapper-color {   
    @include margin(null null rem(20) null);

    @include mq($bp768) {
      width: 40%;
      @include margin(null null rem(30) null);
    }
  }

  .magazine-callout-wrapper {
    blockquote {
      @include margin(rem(32) null null null );

      p {
        @include font-size(21, 25);       
        @include padding(null null null rem(12));
        @include margin(null null null rem(32));
        border-left: none;

        @include mq($bp768) {
          @include font-size(22, 27);
          @include padding(null null null rem(16));
        }
        @include mq($bp1024) {
          @include font-size(26, 30);
          @include padding(null null null rem(20));
        }
      }
      &:before {
        width: rem(30);
        height: rem(27);

        @include mq($bp768) {
          width: rem(32);
          height: rem(29);
        }
        @include mq($bp1024) {
          width:rem(35);
          height: rem(32);
        }
      }
    }

    p.cite {
      @include padding(null null null rem(12));
      @include margin(null null null rem(32));

      @include mq($bp768) {
        @include padding(null null null rem(16));
      }
      @include mq($bp1024) {
        @include padding(null null null rem(20));
      }
    }

    p.cite > span {
      font-weight: 300;
      @include font-size(20, 24);
      display: block;

      @include mq($bp768) {
        @include font-size(24, 28);
      }
    }
  }

  .magazine-callout-wrapper-color {
    img {
      @include margin(null null rem(0) null);
    } 

    blockquote, p.cite {
      background: #fdbb30;
    }
    
    blockquote {
      @include margin(rem(0) null null null);
      @include padding(null null rem(30) null);

      &::before {
        display: block;
        position: absolute;
        top: rem(-22);
        width: rem(36);
        height: rem(36);
        background: $color-supernova;
        background-size: cover;
        content: '';
        left: calc(50% - 1.25rem);
        -webkit-transform: rotate(-225deg) translateY(calc(-50% + 0.75rem));
        transform: rotate(-225deg) translateY(calc(-50% + 0.75rem));
  
        @include mq($bp768) {
          top: rem(-34);
          width: rem(52);
          height: rem(52);
          left: calc(50% - 2.125rem);
        }
      }

      &::after {
        display: block;
        position: absolute;
        top: rem(-20);
        width: rem(36);
        height: rem(36);
        background: url('../images/icons-usf/USF_arrow_white.svg') no-repeat center;
        background-size: contain;
        content: '';
        left: calc(50% - 0.65rem);
        -webkit-transform: rotate(-90deg) translateY(calc(-50% + 0.75rem));
        transform: rotate(-90deg) translateY(calc(-50% + 0.75rem));
  
        @include mq($bp768) {
          top: rem(-32);
          width: rem(50);
          height: rem(50);
        }
      }

      p {  
        @include font-size(20, 28);
        @include padding(rem(40) rem(30) rem(20) rem(30));
        @include margin(null null null rem(0));
        font-weight: 300;      
        color: $color-black;
        text-align: center;       
        border-left: 0;

        @include mq($bp1024) {
          font-size: rem(22);
        }

        strong {
          font-family: inherit;
          font-size: inherit;
          font-weight: 500;
        }
      }
    }
    p.cite {
      margin: unset;
      text-align: center;
      padding-bottom: rem(30)!important;
    }
  
    p.cite > span {
      font-weight: 300;
      font-size: rem(20);
      display: block;

      @include mq($bp768) {
        font-size: rem(24);
      }
    }
  }

  .magazine-callout-wrapper-color.orange {
    blockquote, p.cite {
      background: $color-carrot-orange;
    }
    blockquote {
      &::before {
        background: $color-carrot-orange;
      }
    }
  }
  .magazine-callout-wrapper-color.red {
    blockquote, p.cite {
      background: $color-chilean-fire;
    }
    blockquote {
      &::before {
        background: $color-chilean-fire;
      }
    }
  }
  .magazine-callout-wrapper-color.lime {
    blockquote, p.cite {
      background: $color-atlantis;
    }
    blockquote {
      &::before {
        background: $color-atlantis;
      }
    }
  }
  .magazine-callout-wrapper-color.olive {
    blockquote, p.cite {
      background: $color-wasabi;
    }
    blockquote {
      &::before {
        background: $color-wasabi;
     }
    }
  }
  .magazine-callout-wrapper-color.turquoise {
    blockquote, p.cite {
      background: $color-caribbean-green;
    }
    blockquote {
      &::before {
        background: $color-caribbean-green;
      }
    }
  }
  .magazine-callout-wrapper-color.blue {
    blockquote, p.cite {
      background: $color-iris-blue;
    }
    blockquote {
      &::before {
        background: $color-iris-blue;
      }
    }
  }
}

.f--wysiwyg {
  .cover-wrapper {
    @include maxwidth;

    @include mq($bp768) {
      display: flex;
      flex-wrap: wrap;
    }

    .cover {
      @include margin(null null rem(36) null); 
      @include padding(null rem(20) null rem(20));

      @include mq($bp768) {
        @include margin(null null rem(20) null); 
        @include padding(null rem(0) null rem(0));
        display: inline-block;
        flex-basis: cols(20.5, 44);
        flex-shrink: 0;
      }

      @include mq($bp1024) {
        flex-basis: cols(13.5, 44);
        flex-shrink: 0;
      }

      &:not(:first-child) {
        @include mq($bp768max) {
          @include margin(rem(20) null null);
        }
      }

      &:nth-child(n + 3) {
        @include mq($bp768-1024) {
          @include margin(rem(32) null null);
        }
      }

      &:nth-child(n + 4) {
        @include mq($bp1024) {
          @include margin(rem(30) null null);
        }
      }

      &:nth-child(odd) {
        @include mq($bp768-1024) {
          @include margin(null cols(3, 44) null null);
        }
      }

      &:nth-child(3n + 2) {
        @include mq($bp1024) {
          @include margin(null cols(1.75, 44));
        }
      }

      img {
        width:100%;
      }

      &:hover {
        .cover-image-link {
          img {  
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
            -webkit-box-shadow: 0 0 1rem 0.375rem rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 1rem 0.375rem rgba(0, 0, 0, 0.3);
          }
        }
        a.cover-link {
          text-decoration: underline;
  
          &:after {
            transform: translate3d(rem(3), 0, 0);
            transition: transform $transition-duration-fast $transition-easing-default;
          }
        }
      } 
    
      a.cover-link {
        @extend %font-fira-sans-bold;
        @include font-size(18, 18);
        text-decoration: none;
        text-transform: uppercase;
  
        @include margin(rem(10) null null);
        text-align: right;
  
        @include mq($bp768) {
          @include font-size(20, 20); 
          @include margin(rem(45) null null);
        }
  
        &:after {
          background: url('../images/icons-usf/USF_arrow_green.svg') no-repeat 0 0;
          content: '';
          position: relative;

          @include margin(null null null rem(8));
          display: inline-block;
          width: rem(14);
          height: rem(24);
          top: 5px;
          transition: transform $transition-duration-fast $transition-easing-default;
  
          @include mq($bp768) {
            @include margin(null null null rem(10));
            top: 0px;
            vertical-align: top;
          }
        }
      }
    }
  }
}

.unibuddy_wrapper {
  z-index:101;
  background-color:transparent;
  width: 0;
  height: 0;

  .unibuddy {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 101;
    
    @include mq($bp768max) {
      width: 100vw;
      bottom: 77px;
      right: 2px;
    }

    ._3FMD.Q8tO {
      border: 5px solid white;
      margin-right: 5px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
    }
  } 
} 

div,
.cc--rich-text {
  .f--wysiwyg {
    @extend %rich-text;
  }
}


/* ------  CURATOR FEEDS  ----------------------------*/
/* CSS for Curator THREE POSTS FOR EMBED */
#curator-three-post,
#curator-three-post-text {  
  &.crt-widget-waterfall {
    min-height: unset;
  }
  @include mq($bp768) {
    .crt-col-0, .crt-col-1, .crt-col-2 {
      width: 25%!important;
    }
    .crt-col-0 {
      margin-left: 12.5%;
    }
  }
  @include mq($bp1024) {
    .crt-col-0, .crt-col-1, .crt-col-2 {
      width: 20%!important;
    }
    .crt-col-0 {
      margin-left: 20%;
    } 
  }
  article {
   margin: 0 20px 20px 20px!important;
   @media screen and (min-width: 500px) and (max-width: 767px) {
     margin: 0 10px 20px 10px!important;
   }
    @include mq($bp768) {
      margin: 0 10px 20px 10px!important;
    }
  }
}
#curator-three-post-text { 
  .crt-post .crt-post-header,
  .crt-post-footer {
    display: none;
  }
  .crt-post-c.crt-post-border {
    @include mq($bp768) {
      height: 490px;    
    }
    @include mq($bp1024) {
      height: 40vw;
    }
  }
}
/* CSS for Curator TWO POSTS FOR RICH TEXT */
#curator-two-post {
  .crt-feed {
    @include mq($bp1024) {
      padding-left: 5.88886%;
    }
  }
  article {
    margin-bottom:important
  }
  .crt-col-0 {
    @include mq($bp768) {
      @include margin(null cols(2, 46) 0 null);
      width: calc((100% - 4.34783%) / 2) !important;     
    }
    @include mq($bp1024) {
      width: calc((100% - 11.23669%) / 2) !important;
    }
  }
  .crt-col-1 {
    @include mq($bp768) {
      width: calc((100% - 4.34783%) / 2) !important;
    }
    @include mq($bp1024) {
      width: calc((100% - 11.23669%) / 2) !important;
    }
  }
  .crt-grid-post:nth-child(3),
  .crt-grid-post:nth-child(4) {
    display: none;
  }
  img {
    margin-bottom: 0;
  }
}

/* CSS for Curator ONE POST FOR RICH TEXT WRAP AROUND */
#curator-one-post {
  &.align-right {
    @include mq($bp768) {
      width: 33%;
    }
  }
  &.align-left {
    @include mq($bp768) {
      width: 33%;
    }
  }
  .crt-post .crt-post-header,
  .crt-post-footer ,
  .crt-col-1,
  .crt-col-2 {
    display: none;
  }
}

/* CURATOR 50/50 Split CTA using WATERFALL - Sydney */
.cc--fifty-fifty-split-cta {
  &.image-left {
    .curator-container  {
      #fifty-fifty-split-cta {
        .crt-col-0,
        .crt-col-1 {
          float: right !important;
        }
      }
    }
  }
  &.image-right {
    .curator-container  {
      #fifty-fifty-split-cta {
        .crt-col-0,
        .crt-col-1 {
          float: left !important;
        }
      }
    }
  }
  .curator-container {
    height: 100vw;
    width: 100vw;
    @include mq($bp768) {
      width: 50vw;
      height: 50vw;
    }
    .crt-col-0, .crt-col-1, .crt-col-2, .crt-col-3 {
      width: unset!important;
      height: 50vw;
    }
    .text-image-container {
      .text-container {
        width: 50vw;
        height: 50vw;
      }
    }
    .crt-widget .crt-post-footer,
    .crt-post .crt-post-header {
      display: none;
    }
    .crt-post .crt-post-border {
      height: 100vw;
      width: 100vw;
      @include mq($bp768) {
        height: 50vw;
        width: 25vw;
      }
      @include mq($bp1024) {
        width: 29vw;
      }
    }
    .crt-post-text.with-max-lines {
      p, a {
        font-size: .9rem !important;
      }
    }
  }
}
