.cc--chart-text {
    @include maxwidth;

    .c--chart-text {
        @include padding(null cols(3));

        @include mq($bp768) {
        @include padding(null cols(2));
        }

        canvas.pie-chart,
        canvas.bar-chart,
        canvas.line-chart {
            height:400px;
        }

        .chart-wrapper {
            width:100%;

            .inner-chart-wrapper {
                width:100%;  
                padding:30px 0;      

                @include mq($bp768) {
                    width:45%;
                    padding:20px;
                    min-width:316px;
                }

                &.chart-float-left {
                    padding-left: 0!important;
                }
                &.chart-float-right {
                    padding-right: 0!important;
                }

            }
            .chart-text-wrapper {
                width:100%;

                @include mq($bp768) {
                    width:55%;
                    padding:20px;
                    min-width:387px;
                }

                &.chart-float-left {
                    padding-right: 0!important;
                }
                &.chart-float-right {
                    padding-left: 0!important;
                }

                .f--description {
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        @include margin(null null rem(15));
                        color: $color-sherpa-blue;
                    
                        @include mq($bp768) {
                            @include margin(null null rem(20))
                        }
                    
                        a {
                            text-decoration: underline;
                        }
                    }
                    p {
                        @include font-size(14, 18);
                        color: $color-black;
                        word-break: break-word;
                    
                        @include mq($bp768) {
                            @include font-size(16, 24);
                        }

                        &.intro-text {
                            @extend %font-fira-sans-extra-condensed-regular;
                            @include font-size(24, 30);
                            color: $color-sherpa-blue;
                        
                            @include mq($bp768) {
                                @include font-size(30, 36);
                            }
                        
                            a {
                                font-family: inherit;
                                font-size: inherit;
                                line-height: inherit;
                            }
                            a.cta-link {    
                                @include button($secondary: true);      
                            }
                        }
                    
                        &.cite {
                            font-family: 'Fira Sans Extra Condensed', sans-serif;
                            font-weight: 700;   
                            font-size: 1rem;
                            line-height: 1.2;
                            text-transform: uppercase;    
                            margin-left: 40%;
                            padding-bottom: 2.25rem;
                            
                            @include mq($bp768) {
                                font-size: 1.25rem;
                                margin-top: -2rem;
                                margin-left: 50%;
                            }
                            @include mq($bp1024) {
                                margin-left: 60%;
                            }
                        
                            span.cite-title {
                                font-family: 'IBM Plex Serif', serif;
                                font-weight: 400;
                                font-size: .875rem;
                                line-height: 1.625;
                                text-transform: none;
                                display: block;
                        
                                @include mq($bp768) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }

                    a {
                        @include font-size(14, 18);
                        color: $color-sherpa-blue;
                    
                        @include mq($bp768) {
                            @include font-size(16, 24);
                        }
                    
                        @include hover {
                            color: $color-sherpa-blue;
                            text-decoration: none;
                        }
                    
                        strong,
                        b {
                            font-family: inherit;
                            font-size: inherit;
                            line-height: inherit;
                        }
                    }
                    
                    .note {
                        @extend %font-fira-sans-extra-condensed-regular;
                        @include font-size(13,18);
                    
                        a {
                            font-size: inherit!important;
                            font-family: inherit;
                        }
                        strong {
                            font-size: inherit;
                            font-family: inherit;
                        }
                    }

                    p,
                    ul,
                    ol {
                        &:last-child {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }

                    ul,
                    ol {
                        @include padding(0 null rem(30) 0);

                        strong,
                        b {
                            font-size: inherit;
                            line-height: inherit;
                        }

                        ol,
                        ul {
                            @include padding(rem(24) null 0);
                        }

                        li {
                            @include font-size(14, 18);
                            @include padding(null null null rem(25));
                            @include margin(null null rem(24));
                            position: relative;

                            @include mq($bp768) {
                                @include padding(null null null rem(25));
                                @include font-size(16, 24);
                            }

                            a {
                                color: $color-sherpa-blue;
                                font-family: inherit;
                                font-size: inherit;
                                line-height: inherit;

                                @include hover {
                                    color: $color-sherpa-blue;
                                }
                            }
                        }
                    }

                    ul {
                        list-style: none;

                        > li {
                        &::before {
                            display: block;
                            position: absolute;
                            top: rem(6);
                            left: 0;
                            width: rem(8);
                            height: rem(8);
                            line-height: rem(30);
                            border-radius: 50%;
                            background: $color-supernova;
                            content: '';

                            @include mq($bp768) {
                                top: rem(8);
                            }
                        }
                    }
                }
            }

            ol {
                @include padding(null null null rem(18));

                > li {
                    @include padding(null null null rem(10));

                    &::marker {
                        color: $color-supernova;
                        font-weight: 600;
                    }
                }
            }
            }
        }
        
        .chart-float-right {
            float:right;
        }
        .chart-float-left {
            float:left;
        }
        .chart-clear {
            clear:both;
        }
    }
}
