.f--category {
  @extend %font-fira-sans-extra-condensed-semibold;
  @include font-size(16, 16);
  @include margin(null null rem(8));
  color: $color-primary;
  text-transform: uppercase;

  @include mq($bp768-1024) {
    @include margin(null null rem(18));
  }

  span ~ span {
    &::before {
      @include margin(null rem(8));
      display: inline-block;
      content: '/';
    }
  }

  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
  }
}
