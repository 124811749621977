.cc--profile-pubs {
  .c--profile-pubs {
    @include padding(null cols(1, 40));
    @include margin(rem(50) null);

    @include mq($bp768) {
      @include padding(null cols(2));
    }

    @include mq($bp1024) {
      @include padding(null 0);
    }

    .f--section-title {
      @include margin(null null rem(26));

      @include mq($bp1024) {
        @include margin(null null rem(40));
      }

      h2 {
        @include font-size(25, 28);
        text-align: left;

        @include mq($bp1024) {
          @include font-size(36, 36);
        }
      }
    }

    .views-container {
      .views-row {
        @include padding(rem(20) null);
        border-top: rem(1) solid $color-grey;

        &:last-child {
          border-bottom: rem(1) solid $color-grey;
        }

        .f--link {
          @include margin(null null rem(15));

          @include mq($bp1024) {
            @include margin(null null rem(10));
          }

          a {
            @extend %font-fira-sans-bold;
            @include font-size(16, 22);
            @include quotes;
            display: block;
            position: relative;
            color: $color-orange;
            text-decoration: none;


            @include mq($bp1024) {
              @include font-size(18, 26);
            }

            @include hover {
              text-decoration: underline;
            }

            &.ext {
              @include padding(null rem(28) null null);
            }
          }
        }

        .f--text {
          p {
            @include font-size(14, 19);

            @include mq($bp1024) {
              @include font-size(14, 26);
            }
          }
        }
      }
    }
  }
}
