.cc--footer-social {
  @include margin(rem(20) null null);

  @include mq($bp768max) {
    width: 100%;
  }

  @include mq($bp768) {
    @include margin(rem(30) null null);
  }

  @include mq($bp1024) {
    @include margin(rem(32) null null);
  }

  .footer-social-container {
    .social-media-links--platforms {
      @include no-bullet;
      display: flex;
      width: 100%;

      @include mq($bp768max) {
        justify-content: space-between;
      }

      @include mq($bp768-1024) {
        flex-wrap: wrap;
      }

      > li {

        &:not(:last-child) {
          @include margin(null rem(20) null null);
        }

        a {
          display: flex;
          position: relative;

          svg {
            width: rem(32);
            height: rem(32);
          }

          @include hover {
            path {
              fill: $color-supernova;
            }
          }
        }
      }
    }
  }
}
