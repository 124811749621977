.lc--layout-container {
  .button {
    @include button;
  }

  .button-secondary {
    @include button($secondary: true);
  }

  .button-red {
    @include button($red: true);
  }

  .button-black {
    @include button($black: true);
  }

  .button-white {
    @include button($white: true);
  }

  .button--alt {
    @include button-alt;
  }
}
