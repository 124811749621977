.cc--title-no-image {
  @include margin(rem(20) null);

  @include mq($bp768) {
    @include margin(rem(40) null rem(20));
  }

  @include mq($bp1024) {
    @include margin(rem(60) null rem(30));
  }

  .f--category {
    margin-bottom: rem(20);
  }

  .c--title-no-image {
    @include maxwidth;
    text-align: center;
  }

  .f--page-title {
    h1 {
      @include font-size(36, 36);
      letter-spacing: normal;

      @include mq($bp768) {
        @include font-size(60, 60);
      }
    }
  }
}
