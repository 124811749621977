.cc--login-form {
  @include margin(rem(25) null);

  @include mq($bp768) {
    @include margin(rem(40) null);
  }

  @include mq($bp1024) {
    @include margin(rem(50) null);
  }

  .c--login-form {
    @include maxwidth(320px);
    @include padding(null cols(2));
    width: 100%;

    @include mq($bp768) {
      @include padding(null 0);
    }
  }

  .user-login-form {
    @include padding(0);
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    .form-item {
      @include margin(null 0 rem(20) null);
      width: 100%;

      &:last-child {
        @include margin(null null 0 null);
      }

      label {
        @extend %font-fira-sans-bold;
        @include font-size(14, 16);
        @include margin(null null rem(10));
        display: block;
        color: $color-black;

        @include mq($bp768) {
          @include font-size(16, 21);
          @include margin(null null rem(20));
        }
      }

      input[type='text'],
      input[type='password'] {
        @extend %input-style;
      }

      .description {
        @include margin(rem(5) null);
      }
    }

    .form-actions {
      @include margin(0 null null);
      width: 100%;

      .form-item {
        @include margin(null 0 null null);
        width: 100%;

        &:first-child {
          > input {
            @include button;
          }
        }
      }
    }
  }
}

#block-sju-local-tasks {
  @include margin(null null rem(40));
  @include padding(rem(30) null null);
  position: relative;

  @include mq($bp768) {
    @include margin(null null rem(30));
    @include padding(rem(40) null null);
  }

  ul {
    @include no-bullet;
    position: relative;
    text-align: center;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: rem(1) solid $color-grey;
      content: '';
    }

    li {
      @include font-size(14, 16);
      @include padding(0 rem(8));
      display: inline-block;
      position: relative;
      z-index: 1;

      @include mq($bp768) {
        @include font-size(18, 20);
        @include padding(0 rem(30));
      }

      a {
        @include padding(rem(5) 0 rem(20));
        display: block;
        position: relative;
        color: $color-link;
        text-decoration: none;

        &.is-active {
          color: $color-black;

          &::after {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: rem(1) solid $color-grey;
            content: '';
          }
        } // &.is-active

        &:hover {
          color: $color-link-hover;

          &::after {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: rem(1) solid $color-grey;
            content: '';
          }
        }
      }
    }
  }
}
