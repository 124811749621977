$image-border-radius: 0;
$text-color: $color-secondary;

.cc--profile-card-horizontal {
  @include margin(null null rem(40));
  @include clearfix;

  .c--profile-card-horizontal {
    @include mq($bp768max) {
      text-align: center;
    }

    @include mq($bp768-1024) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .image-container {
    @include mq($bp768max) {
      @include margin(null auto rem(15));
      max-width: rem(176);
    }

    @include mq($bp768) {
      width: cols(8, 28);
      float: left;
    }

    img {
      border-radius: $image-border-radius;
    }
  }

  .text-container {
    @include mq($bp768max) {
      @include margin(null null rem(15));
    }

    @include mq($bp768) {
      @include padding(null null null cols(2, 28));
      @include margin(null null null auto);
      width: cols(20, 28);
    }

    @include mq($bp1024) {
      @include padding(rem(20) null null cols(2, 28));
    }

    h3 {
      a {
        @include font-size(22, 30);

        @include mq($bp768) {
          @include font-size(24, 29);
        }

        @include mq($bp1024) {
          @include font-size(26, 32);
        }

        @include hover {
          color: $text-color;
          text-decoration: underline;
        }
      }
    }

    .f--text {
      @include font-size(14, 22);
      color: $text-color;
    }
  }

  .data-wrapper {
    @include mq($bp768) {
      display: flex;
    }

    @include mq($bp768-1024) {
      @include margin(rem(20) null null);
      width: 100%;
    }

    @include mq($bp1024) {
      @include padding(rem(30) null null cols(2, 28));
      @include margin(null null null auto);
      width: cols(20, 28);
    }

    > div {
      &:not(:last-child) {
        @include mq($bp768) {
          @include margin(null cols(2, 28) null null);
        }

        @include mq($bp1024) {
          @include margin(null cols(1, 17) null null);
        }
      }
    }

    label {
      @extend %font-fira-sans-bold;
      @include margin(null null rem(5));
      @include font-size(14, 16);
      display: block;
      color: $text-color;

      @include mq($bp768) {
        @include margin(null null rem(6));
      }
    }

    a {
      @extend %font-fira-sans-regular;
    }
  }

  .department,
  .office {
    @include mq($bp768max) {
      @include margin(null null rem(15));
    }
  }

  .department,
  .office,
  .contact {
    @include mq($bp768-1024) {
      flex: 0 0 cols(8, 28);
      width: cols(8, 28);
    }

    @include mq($bp1024) {
      flex: 0 0 cols(5, 17);
      width: cols(5, 17);
    }

    div,
    p,
    a {
      @include font-size(16, 23);
    }

    a {
      display: block;
    }
  }

  .department {
    a {
      display: inline;
    }
  }
}
