// gradient overlay
$background-color-fallback: $color-secondary;
$corner-color: $color-primary;
$play-btn: $color-white;
$top-gradient: linear-gradient(to top, transparent, rgba($color-black, 1));

.cc--hero-home-item-slide {
  .c--hero-home-item-slide {
    position: relative;
    height: 100%;
    overflow: hidden;

    @include mq($bp1024) {
      display: flex;
      width: 100%;
    }
  }

  &.swiper-slide {
    height: auto;
  }

  .image-video {
    position: relative;
    width: 100%;
    overflow: hidden;

    @include mq($bp768-1024) {
      height: calc(100vh - 70px);
    }

    @include mq($bp1024) {
      width: cols(32);
      min-height: 100vh;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 31%;
        background-image: $top-gradient;
        content: '';
        opacity: 0.7;
        z-index: 1;
      }
    }

    .f--image {
      @include mq($bp768max) {
        position: relative;
        padding-bottom: 100%;

        &::after {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: rem(100);
          background-image: linear-gradient(
            to top,
            $background-color-fallback 0,
            rgba($background-color-fallback, 0) 100%
          );
          content: '';
          z-index: 1;
        }
      }

      @include mq($bp768) {
        height: 100%;
      }

      @include mq($bp1024) {
        min-height: 100vh;
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-color: $background-color-fallback;
      z-index: 1;
      object-fit: cover;

      @include mq($bp768max) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .f--ambient-video {
      display: none;

      @include mq($bp768) {
        display: block;
        height: 100%;

        + .f--image {
          display: none;
        }
      }

      video {
        position: static;
      }
    }
  }

  &.swiper-slide-active {
    .video-controls {
      @include mq($bp768) {
        display: block;
      }
    }
  }

  .video-controls {
    display: none;
    position: absolute;
    z-index: 205;

    @include mq($bp768) {
      top: rem(9);
      right: rem(3);
      bottom: auto;
    }

    @include mq($bp1024) {
      top: auto;
      right: rem(11);
      bottom: rem(14);
    }

    .video-unmute-button {
      display: none;
    }

    .video-pause-button {
      svg {
        path {
          fill: $play-btn;
        }
      }
    }

    .video-play-button {
      svg {
        rect {
          fill: $play-btn;
        }
      }
    }
  }

  .text-container-wrapper {
    @include margin(null auto);
    position: relative;
    width: cols(42);
    margin-top: -40px;
    background: $color-sherpa-blue;
    z-index: 200;

    @include mq($bp768) {
      width: cols(44);
      margin-top: -106px;
    }

    @include mq($bp1024) {
      @include margin(0);
      width: cols(16);
      height: auto;
    }
  }

  .text-container {
    @include padding(rem(40) cols(3, 42) rem(30));
    display: flex;
    position: relative;
    bottom: 0;
    align-items: baseline;
    width: 100%;
    height: 100%;
    z-index: 3;

    @include mq($bp768) {
      @include padding(rem(60) cols(2, 44) rem(40));
    }

    @include mq($bp1024) {
      @include padding(rem(150) cols(1, 16) rem(40));
    }

    .arrow {
      position: absolute;
      top: rem(-10);
      left: rem(16);

      @include mq($bp1024) {
        top: rem(157);
        left: rem(-25);
      }

      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: rem(50);
        height: rem(50);
        transform: rotate(45deg);
        background: $color-sherpa-blue;
        content: '';
      }

      svg {
        position: relative;
        z-index: 1;

        @include mq($bp1024) {
          top: rem(-3);
          left: rem(10);
        }

        @include mq($bp1024max) {
          top: rem(-4);
          left: rem(17);
          width: rem(15);
          height: auto;
          transform: rotate(90deg);
          z-index: 1;
        }
      }
    }

    .text-container-inner {
      @include mq($bp768-1024) {
        display: flex;
      }

      @include mq($bp1024) {
        max-width: rem(350);
      }
    }

    .content-wrapper {
      @include mq($bp768-1024) {
        @include padding(null null null cols(2, 40));
        width: cols(20, 40);
      }
    }

    .f--section-title {
      position: relative;
      z-index: 1;

      @include mq($bp768-1024) {
        width: cols(20, 40);
      }

      h2 {
        @extend %font-fira-sans-extra-condensed-regular;
        @include font-size(36, 36);
        color: $color-white;
        letter-spacing: rem(-.5);
        text-transform: none;

        strong {
          @extend %font-fira-sans-extra-condensed-semibold;
        }

        a {
          color: $color-white;
        }

        @include mq($bp768) {
          @include font-size(48, 50);
        }

        @include mq($bp1024) {
          @include font-size(60, 62);
        }
      }
    }

    .f--description {
      &,
      p {
        @extend %font-ibm-plex-serif-regular;
        @include font-size(15, 25);
        @include margin(0);
        color: $color-white;

        @include mq($bp768) {
          @include font-size(16, 24);
        }

        a {
          color: $color-white;

          @include hover {
            color: $color-white;
            text-decoration: underline;
          }
        }
      }
    }

    .f--link {
      @include margin(rem(20) null null null);

      @include mq($bp768) {
        @include margin(rem(60) null rem(15) null);
      }

      @include mq($bp1024) {
        @include margin(rem(70) null null null);
      }

      .link {
        @extend %font-fira-sans-bold;
        @include font-size(18, 18);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        height: rem(50);
        border: rem(2) solid $color-supernova;
        color: $color-white;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        @include hover {
          border-width: rem(5);
        }

        @include mq($bp768) {
          width: rem(240);
        }

        @include mq($bp1024) {
          width: rem(200);
        }
      }
    }
  }
}
