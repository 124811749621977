.cta-wrapper {
  display: flex;
  justify-content: center;

  .cta-link {
    @include button($secondary: true);
    @include margin(null cols(3) rem(50) cols(3));

    @include mq($bp768max) {
      + .cta-link {
        @include margin(rem(20) null null);
      }
    }

    @include mq($bp768) {
      @include margin(null null rem(30) null);
    }

    @include mq($bp1024) {
      @include margin(null null rem(50) null);
    }
  }
}