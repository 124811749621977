// https://github.com/thoughtbot/bourbon


@function _compact-shorthand($values) {
  $output: null;

  $a: nth($values, 1);
  $b: if(length($values) < 2, $a, nth($values, 2));
  $c: if(length($values) < 3, $a, nth($values, 3));
  $d: if(length($values) < 2, $a, nth($values, if(length($values) < 4, 2, 4)));

  @if strip-unit($a) == 0 { $a: 0; }
  @if strip-unit($b) == 0 { $b: 0; }
  @if strip-unit($c) == 0 { $c: 0; }
  @if strip-unit($d) == 0 { $d: 0; }

  @return $a $b $c $d;
}


@mixin _directional-property(
  $property,
  $suffix,
  $values
) {
  $top:    $property + "-top"    + if($suffix, "-#{$suffix}", "");
  $bottom: $property + "-bottom" + if($suffix, "-#{$suffix}", "");
  $left:   $property + "-left"   + if($suffix, "-#{$suffix}", "");
  $right:  $property + "-right"  + if($suffix, "-#{$suffix}", "");
  $all:    $property +             if($suffix, "-#{$suffix}", "");

  $values: _compact-shorthand($values);

  @if _contains-falsy($values) {
    @if nth($values, 1) { #{$top}: nth($values, 1); }

    @if length($values) == 1 {
      @if nth($values, 1) { #{$right}: nth($values, 1); }
    } @else {
      @if nth($values, 2) { #{$right}: nth($values, 2); }
    }

    @if length($values) == 2 {
      @if nth($values, 1) { #{$bottom}: nth($values, 1); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 3 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 2) { #{$left}: nth($values, 2); }
    } @else if length($values) == 4 {
      @if nth($values, 3) { #{$bottom}: nth($values, 3); }
      @if nth($values, 4) { #{$left}: nth($values, 4); }
    }
  } @else {
    #{$all}: $values;
  }
}


@mixin padding($values) {
  @include _directional-property(padding, null, $values);
}


@mixin margin($values) {
  @include _directional-property(margin, null, $values);
}


@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
