// use https://bennettfeely.com/clippy/ to get the polygon points
// if Triangle
// polygon(red, green, yellow);
// $points: (
// 		'upper left': '0,#{$height} #{$width},#{$height} #{$width},0',
// 		'upper right': '0,#{$height} #{$width},#{$height} 0,0',
// 		'lower left': '0,0 #{$width},#{$height} #{$width},0',
// 		'lower right': '0,0 #{$width},0 0,#{$height}'
// 	);
// if Trapezoid
// polygon(red, green, yellow, blue)
// $points: (
// 		'angled left': '0 0, 100 0, #{$width} #{$height}, 0 #{$height}',
// 		'angled right': '100 #{$width}, 100 0, 100 #{$height}, 0 #{$height}',
// 	);
// Example Usage:
// @include svg-angled-edge($height, $fill, $points);
// @include svg-angled-edge(28, $color-secondary, (0 0, 100 0, 100 75, 0 40));
@mixin svg-angled-edge($height, $fill, $points) {

  // ensure $fill color is using rgb()
  $fill-rgb: 'rgb(' + round(red($fill)) + ',' + round(green($fill)) + ',' + round(blue($fill)) + ')';

  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: rem($height);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 #{$height}' fill='#{$fill-rgb}' fill-opacity='1'%3E%3Cpolygon points='#{$points}'%3E%3C/polygon%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  content: '';
  z-index: 1;
}
