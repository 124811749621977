@mixin outline($color: $color-focus, $colorTwo:  $color-white) {
  html[data-whatinput="keyboard"] & {
    box-shadow: 0 0 0 3px $colorTwo;
    outline: 3px solid $color;
    outline-offset: 3px;
    z-index:1000;
  }

  html[data-whatinput="mouse"] & {
    outline: none;
  }
}

