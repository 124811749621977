.cc--virtual-tour {
  @include maxwidth;
  @include clearfix;

  figure,
  img {
    @include margin(null null rem(10));

    @include mq($bp768) {
      @include margin(null null rem(15))
    }
  }

  img {
    @include margin(null null rem(10));
    display: none;//block;
    width: auto;
    position: relative;
    top: 75px;

    @include mq($bp1024) {
      @include margin(null null rem(30));
    }
  }

  figure {
    @include margin(null null rem(35));

    @include mq($bp768) {
      @include margin(null null rem(70));
    }

    figcaption {
      @extend %font-fira-sans-extra-condensed-regular;
      @include font-size(14, 18);
      color: $color-black;
      font-style: normal;
      text-align: left;
      text-transform: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrapper {
  width: 100vw;
  height: 445px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .tour-image {
    @include mq($bp1024) {
      margin-top: 12px;
    }
  }

  .tour-text {
    text-align: center;
    position: relative;
    top: -140px;

    p {  
      @extend %font-fira-sans-extra-condensed-semibold;
      @include font-size(32, 24);
      color: $color-white!important;
      text-shadow: 2px 2px 4px black;

      a.cta-link {    
        @include button($secondary: true);          
        text-shadow: none;
      }
    }
  }

  .pnlm-ui.pnlm-grab {
      display: none;
  }

  .pnlm-render-container {
    height: 565px;
    width: 100vw;
  }
}
