%select-wrap,
.select-wrap {
  position: relative;
  border: rem(1) solid $color-atlantis;
  background-color: $color-white;

  select {
    @extend %font-fira-sans-extra-condensed-regular;
    @include padding(null rem(35) null rem(10));
    @include font-size(16, 26);
    width: 100%;
    height: rem(50);
    border: 0;
    background: transparent;
    background-image: none;
    color: $color-secondary;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include mq($bp768max) {
      height: rem(50);
    }

    &::-ms-expand {
      display: none;
    }

    option {
      text-wrap: wrap;
      padding-top: rem(5);
      padding-bottom: rem(5);
    }
  }

  select[name="field_program"],
  select[name="field_topics"],
  select[name="field_event_category"]   {
    height: rem(50);
    text-wrap: wrap;
    line-height: normal;
  }

  svg {
    @include centered('y');
    right: rem(20);//15
    width: rem(15);
    height: rem(15);
    pointer-events: none;
  }
}
