$hamburger-padding-x                       : 0px !default;
$hamburger-padding-y                       : 20px !default;
$hamburger-layer-width                     : 38px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 10px !default;
$hamburger-layer-color                     : #FFFFFF !default;
$hamburger-layer-border-radius             : 0px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-types                           : (squeeze)
