.cc--program-listing {
  @include margin(null null rem(60));

  .c--program-listing {
    > .f--link {
      @include margin(rem(30) null null);
      display: flex;
      justify-content: center;

      @include mq($bp768) {
        @include margin(rem(40) null null);
      }

      @include mq($bp1024) {
        @include margin(rem(60) null null);
      }

      a {
        @include button($secondary: true);
      }
    }
  }

  .cc--accordions {
    margin: 0;

    .f--cta-title {
      h3 {
        @include margin(null null rem(56));

        @include mq($bp768max) {
          @include font-size(30, 36);
          text-align: center;
        }
      }
    }

    .c--accordions {
      > ul {
        > li {
          a {
            @extend %font-fira-sans-extra-condensed-semibold;
            color: $color-sherpa-blue;
            text-decoration: none;
            display: inline-block;

            @include padding(rem(20) null rem(0) null);

            .item-title {
              @include font-size(30, 36);

              @include mq($bp768) {
                @include font-size(36, 36);
              }
            }

            &:hover {
              text-decoration: underline;
            }
          }

          .f--description {
            @include padding(null null rem(20) null);
          }
        }
      }
    }
  }
}
