
#virtual-tour-video--video {
    object-fit: initial;
    height: 565px;
    overflow: hidden;
    @include mq($bp768) { 
        width:100%;
        height: auto;
    }
}

.wrapper {  
    position: relative;

    .tour-image,
    .tour-video {
        &.virtual-tour-video {
            width: 100vw;

          img {
                width:unset;
                @include mq($bp768) { 
                    width:100vw;
                }
            }
        }
    }
    .tour-text {
        &.virtual-tour-video {
            position: absolute;
            top: 300px;
            //left: calc((100vw - 200px) / 2);
            left: calc((100vw - 100%) / 2);
            width: 100%;

            @include mq($bp768max) { 
                left: calc((100vw - 250px) / 2);
                width: 250px;
            }
        }
    }
}
  
