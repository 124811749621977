.school-magazine {
  .c--component.c--header {
    background: white;
  }
  .tc--landing-page {
    @include mq($bp1024) {
      margin-top: -8.75rem;
    }
  }
  .header-buttons {
    @include mq($bp768max) {
      padding-right: 5px;
    }
  }
  #block-sitebranding-magazinemegamenu {
    a { 
      svg {
        width: 15rem;
        @include mq($bp768) {
          width: 23rem;
        }
        @include mq($bp1024) {
          width: 18.75rem;
        }
      }
    }
  }
  #block-sitebranding-magazine{
    a { 
      svg {
        width: 13rem;
        @include mq($bp768) {
          width: 14rem;
        }
        @include mq($bp1024) {
          width: 15rem;
        }
        .logo-magazine-usf {
          stroke: none!important;
          fill: black;
        }
        .logo-magazine-text {
          fill: black;
          stroke: black;
        }
      }
    }
    &.cc--branding-header {
      .c--branding-header {
        background:white;
        height: 8.125rem;
        @include mq($bp768max) {
          height: 5.125rem;
        }
        @include mq($bp768) {
          padding-left: 2.1vw;
          height: 6rem;
          padding-top: 3.5rem;
        }
        @include mq($bp1024) {
          padding-top: 3.45rem;
          padding-left: 10%;
          height: 9.375rem;
        }
      }
    }
  }

  #block-headermagazine {
    @include mq($bp768max) {
      position: absolute;
      top: 80px;//470px;
      margin-left: 0;
      padding-left: 1.25rem;
      width: 100vw;
      background-color: white;
      border-top: 1px solid $color-bright-grey;
    }
    @include mq($bp768) {
      top: 77px;
    }
    @include mq($bp1024) {
      top: 42px;
      width:50%;
    }
  }
  
  .cc--utility-menu {
    .c--utility-menu {
      @include mq($bp1024) {
       // @include padding(rem(55) cols(3, 94) null null);//3, 34
      }
      button.list-toggle {
        @extend %font-fira-sans-extra-condensed-semibold;
        @include font-size(18, 16);
        @include padding(null rem(13) null null); //(null rem(13) null rem(20));
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: rem(50);
        margin: 0;
        color: $color-black;
        text-align: left;
        text-transform: uppercase;

        @include mq($bp768) {
          display: none;
        }
        @include mq($bp768max) {
          @include padding(null rem(5) null null)
        }

        &.open {
          svg {
            transform: rotate(-90deg);
          }
        }

        svg {
          width: rem(25);
          height: rem(25);

          @include mq($bp768max) {
            width: rem(41);
            height: rem(41);
          }

          polygon  {
            fill: $color-black;
          }
        }
      }
    }
    .m--menu {
      &.m--utility-magazine {
        @include mq($bp768max) {
          padding-right: 1.25rem;
          padding-bottom: 2.5rem;
          a {
            font-size: 1.25rem;
            height: 3.75rem;
            @include padding(rem(10) null null null);
          }
        }
        
        @include mq($bp768) {
          background: white;
          display: flex;
          justify-content: flex-end;
          a {
            font-size: .9rem;
          }
          .utility-button-wrapper {
            flex-wrap: nowrap;
            .information-for-toggle {
              width: 1.5rem;
              margin-right: -5px;
              padding-bottom: 20px;
            }
          }
          span.pipe {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @include mq($bp1024) {
          a {
            font-size: 1.125rem;
          }
        }
      }
      &.m--utility-magazine > li {
        margin-left:0;
        padding-top: 4px;

        @include mq($bp768max) {
          border-bottom: 0.0625rem solid $color-supernova;
        }
        @include mq($bp1024) {
          padding-top: 3px;
        }
              
        &:first-child a,
        &:nth-child(2) a,
        a {
          font-weight: 400;
          line-height: 1.2;
          color: $color-black;
          text-decoration: none;

          @include mq($bp768) {
            font-weight: 600;
            text-transform: uppercase;
            text-wrap: nowrap;
            white-space: nowrap;
            padding-bottom: 5px;
          }
        }
      }
      span.pipe {
        font-weight: 600;
        @include mq($bp768max) {
          display: none;
        }
        @include mq($bp1024) {
          padding-left: 15px;
          padding-right: 15px;
        }
        &:last-of-type {
          display: none;
        }
      }
      > li {
          @include mq($bp768) {
              margin-bottom: 0;
              text-wrap: nowrap;
              white-space: nowrap;
          }
          .submenu.magazine {
            @include mq($bp768max) {
                margin-left: 0.25rem;
                border: none;
            }
            @include mq($bp768) {
                @include padding(rem(25));
                position: absolute;
                top: rem(27);
                left: 0;
            }
    
            background: $color-white;
            border: 1px solid $color-aluminium;
            left: -26px!important;
    
            span.pipe {
                display: none;
            }
            .menu-item { 
              @include mq($bp768max) {
                margin-bottom: 1rem;
                text-wrap: wrap;
                white-space: normal;
                &:last-of-type {
                    margin-bottom: 0;
                }
              }
              a {
                line-height: 1.5; 
                text-transform: capitalize;
                text-wrap: wrap;
                white-space: normal;
                color:$color-black;
              
              @include mq($bp768) {
                  font-size: 1rem;
              }
  
              &:hover {
                  color:$color-black!important;
              }
            } 
          } 
        }
      }
      button.utility-button.information-for-toggle {
        @include mq($bp768max) {
          padding-right: 1rem;
          width: 2.1875rem;
          height: 3.75rem;
          text-align: right;
          min-width: 15%;
        }
        svg {
          width:22px;
          height: 10px;
          path {
            fill: $color-black;
          }
        }
      }
    }
  }
  .cc--header {
    .desktop-nav .mobile-menu-trigger,
    .search-trigger-button {
      background: $color-white;

      &.is-active {
        background: none;
      }
  
      svg {
        g,
        path {
          fill: $color-gray-suit; 
        }
      }
    }
  }
  #main-content,
  #l--main-footer {
    position: relative;
    top: 50px;
    @include mq($bp768) {
      top: 0px;
    }
    @include mq($bp1024) {
      top: 140px;
    }
  }
  .m--utility-magazine {
    display: none;
  }
  .submenu {
    display: none;
  }
  .list-toggle.open + .m--utility-magazine {
    display: block;
  }
  .open + .submenu {
    display: block;
  }
}