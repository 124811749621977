*:focus {
  @include outline;
}

.visually-hidden {
  @include visuallyhidden;
}

.skip-link {
  position: absolute;
  left: rem(20);
  padding: rem(5);
  color: $color-black;
  background-color: $color-white;
  z-index: 1000;

  &:not(:focus) {
    @include visuallyhidden;
  }
}
