// ibm plex serif
%font-ibm-plex-serif-regular {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 400;
}

%font-ibm-plex-serif-regular-italic {
  font-family: 'IBM Plex Serif', serif;
  font-style: italic;
  font-weight: 400;
}

%font-ibm-plex-serif-bold {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
}

%font-ibm-plex-serif-bold-italic {
  font-family: 'IBM Plex Serif', serif;
  font-style: italic;
  font-weight: 700;
}

// Fira Sans
%font-fira-sans-regular {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
}

%font-fira-sans-regular-italic {
  font-family: 'Fira Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
}

%font-fira-sans-semibold {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
}

%font-fira-sans-semibold-italic {
  font-family: 'Fira Sans', sans-serif;
  font-style: italic;
  font-weight: 600;
}

%font-fira-sans-bold {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 700;
}

%font-fira-sans-bold-italic {
  font-family: 'Fira Sans', sans-serif;
  font-style: italic;
  font-weight: 700;
}

// Fira Sans Extra Condensed
%font-fira-sans-extra-condensed-light {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-weight: 300;
}

%font-fira-sans-extra-condensed-light-italic {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-style: italic;
  font-weight: 300;
}

%font-fira-sans-extra-condensed-regular {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-weight: 400;
}

%font-fira-sans-extra-condensed-regular-italic {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-style: italic;
  font-weight: 400;
}

%font-fira-sans-extra-condensed-medium {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-weight: 500;
}

%font-fira-sans-extra-condensed-medium-italic {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-style: italic;
  font-weight: 500;
}

%font-fira-sans-extra-condensed-semibold {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-weight: 600;
}

%font-fira-sans-extra-condensed-semibold-italic {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-style: italic;
  font-weight: 600;
}

%font-fira-sans-extra-condensed-bold {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-weight: 700;
}

%font-fira-sans-extra-condensed-bold-italic {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  font-style: italic;
  font-weight: 700;
}
