$text-color: $color-secondary;

.cc--pager {
  @include padding(rem(30) cols(3) null);

  @include mq($bp768) {
    @include padding(null cols(2));
  }

  .cc--component-container & {
    @include padding(null 0)
  }

  .c--pager {
    ul {
      @include no-bullet;
      text-align: center;

      li {
        @include margin(null rem(10) null null);
        display: inline-block;

        @include mq($bp768) {
          @include margin(null rem(30) null null);
        }

        a {
          @include padding(rem(7) rem(10));
          @include font-size(16, 16);
          display: block;
          width: rem(30);
          height: rem(30);
          color: $color-black;
          text-decoration: none;

          span {
            @include font-size(16, 16);
          }
        }

        &.is-active {
          a {
            background-color: $color-primary;
            color: $color-white;
            text-decoration: none;
          }
        }

        &.pager__item--next,
        &.pager__item--previous {
          a {
            text-decoration: none;

            svg {
              height: rem(14);
              fill: $color-black;
            }
          }
        }

        &.pager__item--ellipsis {
          color: $color-black;
        }

        &.pager__item--first,
        &.pager__item--last {
          display: none;
        }
      }
    }
  }

  &.load-more {
    .pager__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      .line {
        flex: 1 0 auto;
        height: rem(1);
        background: $color-border;
      }

      svg {
        @include margin(null null null rem(15));
      }

      .text {
        @extend %font-fira-sans-regular;
        @include margin(null rem(30) null);
        @include font-size(14, 20);
        padding: 0;
        width: auto;
        height: auto;
        color: $text-color;
        text-decoration: none;
      }
    }
  }
}

.pager {
  text-align: center;

  @include mq($bp768) {
    text-align: unset;
  }

  li.pager__item {
    display: inline-block;
  }
  li.pager__item a {
    @extend %font-fira-sans-regular;
    @include font-size(14, 16);
    @include padding(null rem(10));
    color: $color-dove-gray;
    text-decoration: none;

    @include hover {
      text-decoration: underline;
    }
  }

  li.pager__item.is-active a {
    @extend %font-fira-sans-semibold;
    color: $color-black;
    text-decoration: underline;
  }

  li.pager__item.pager__item--previous a {
    &::before {
      display: inline-block;
      background: url('../images/icons-usf/USF_arrow_green.svg') no-repeat 0 0;
      width: rem(14);
      height: rem(21);
      content: '';
      transition: transform .2s ease;
      transform: rotate(-180deg);
      position: absolute;
      @include margin(null null null rem(-12));

      @include mq($bp768) {
        @include margin(rem(3) null null rem(-12));
      }
    }
  }

  li.pager__item.pager__item--next {
    @include margin(null null null rem(10));
    
    a {
      &::after {
        display: inline-block;
        background: url('../images/icons-usf/USF_arrow_green.svg') no-repeat 0 0;
        width: rem(14);
        height: rem(21);
        content: '';
        transition: transform .2s ease;
        position: absolute;

        @include mq($bp768) {
          @include margin(rem(3) null null rem(-12));
        }
      }
    }
  }
}
