$background-color: $color-sherpa-blue;

.cc--footer {
  background-color: $background-color;

  .c--footer {
    @include maxwidth;

    .footer-main {
      @include padding(rem(40) cols(3) rem(60));

      @include mq($bp768) {
        @include padding(rem(60) cols(1) rem(40));
      }

      @include mq($bp1024) {
        @include padding(rem(60) cols(2) rem(40));
      }

      .footer-top {
        @include padding(null null rem(30));

        @include mq($bp768) {
          display: flex;
          justify-content: space-between;
        }

        @include mq($bp1024) {
          @include padding(null null rem(40));
        }
      }

      .footer-top.marketing {
        @include padding(null null rem(30));

        @include mq($bp1024) {
          @include padding(null null rem(40));
          display: flex;
          justify-content: space-between;
        }
        #block-footer-sitebranding {
          .c--component.c--branding-footer {
            width: 300px;
          }
        }
      }
      .branding-social-container {
        width: 100%;

        @include mq($bp768max) {
          display: flex;
          flex-wrap: wrap;
        }

        @include mq($bp768) {
          flex-basis: cols(11, 46);
        }

        @include mq($bp1024) {
          flex-basis: cols(11, 44);
        }
      }

      .menu-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include mq($bp768) {
          flex-basis: cols(33, 46);
          flex-wrap: nowrap;
        }

        @include mq($bp1024) {
          flex-basis: cols(30, 44);
          justify-content: space-between;
        }

        .cc--footer-menu {
          &:last-child {
            @include mq($bp768max) {
              order: 1;
            }
          }

          &:not(:last-child) {
            @include mq($bp768max) {
              order: 2;
            }
          }
        }
      }
      .marketing {
        .menu-container {    
          @include mq($bp768) {
            display: block;
          }
          @include mq($bp1024) {
            display: flex;
            justify-content: flex-end!important; 
            flex-basis: cols(29, 46)!important;
          }
        }
        .branding-social-container {
          width: 100%;
          flex-basis: unset;
  
          @include mq($bp1024max) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
          }

          @include mq($bp1024) {
            flex-basis: cols(11, 44);
          }
        }
      }
      .footer-bottom {
        @include padding(rem(30) null null);
        border-top: rem(1) solid $color-supernova;

        @include mq($bp1024) {
          @include padding(rem(40) null null);
        }

        .info-container {

          @include mq($bp768max) {
            display: flex;
          }

          @include mq($bp1024) {
            display: flex;
            justify-content: space-between;
          }

          .block-system-branding-block {
            @include mq($bp768max) {
              @include padding(null null null cols(1.5, 39));
              flex-basis: 50%;
            }
          }

          .f--wysiwyg {
            @extend %font-ibm-plex-serif-regular;
            @include margin(0 null null);
            color: $color-white;

            @include mq($bp768) {
              @include margin(rem(15) null null);
              text-align: center;
            }

            @include mq($bp1024) {
              @include margin(0 null null);
              text-align: left;
            }

            p {
              @include font-size(12, 24);
              color: inherit;
              letter-spacing: rem(-.19);
            }
          }
        }
      }
    }
  }
}
