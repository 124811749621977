$link-icon: url('../images/icons/icon-external-link.svg') no-repeat center;
$radius: 0;

.cc--newsroom-news-article-listing-component {
  @include padding(rem(30) null);
  border-top: 1px solid $color-border;

  &:first-child {
    @include padding(0 null null);
    border-top: 0;
  }

  &.with-image {
    .c--newsroom-news-article-listing-component {
      display: flex;

      @include mq($bp768-1024) {
        display: block;
      }

    }

    .text-container {
      @include mq($bp1024) {
        @include padding(null null null cols(2, 28));
        flex: 0 0 cols(16, 28);
        width: cols(16, 28);
      }
    }

    .image-container {
      @include mq($bp1024max) {
        flex: 0 0 calc(50% - #{cols(3, 42)});
      }

      @include mq($bp1024) {
        flex: 0 0 cols(12, 28);
        width: cols(12, 28);
      }

      a {
        display:block;
     }

    }

    .logo-container {
      @include mq($bp768) {
        width: cols(6, 15);
      }

      @include mq($bp1024) {
        width: cols(4, 14);
      }
    }
  }

  .f--category {
    @include margin(null null rem(6));
  }

  .f--cta-title {
    @include margin(null null rem(10));

    &,
    h3,
    a {
      @include font-size(22, 26);
      color: $color-sherpa-blue;

      @include mq($bp768) {
        @include font-size(20, 23);
      }

      @include mq($bp1024) {
        @include font-size(26, 32);
      }
    }

    a {
      @include hover {
        text-decoration: underline;
      }
    }

    a[target*='_blank'] {
      &::after {
        @include margin(null null null rem(10));
        display: inline-block;
        width: rem(25);
        height: rem(25);
        background: $link-icon;
        background-size: contain;
        content: '';
        vertical-align: middle;
      }
    }
  }

  .f--wysiwyg {
    @include margin(null null rem(10));
  }

  .f--date-string {
    @extend %font-fira-sans-extra-condensed-regular;
    @include margin(null null rem(6));
    @include font-size(14, 14);
    color: $color-black;
    letter-spacing: rem(-.12);

    @include mq($bp768) {
      @include margin(rem(18) null rem(18));
      @include font-size(16, 16);
      letter-spacing: rem(-.14);
    }

    @include mq($bp1024) {
      @include margin(0 null null);
    }
  }

  .logo-container {
    @include margin(rem(15) null null);

    @include mq($bp768) {
      width: cols(6, 28);
    }

    @include mq($bp1024) {
      width: cols(4, 28);
    }

    img {
      width: auto;
    }
  }

  .image-container {
    img {
      border-radius: $radius;
    }
  }
}
