.cc--ribbon-carousel-slide {
  &.slide-shadow {
    @include hover {
      position: relative;
      box-shadow: 0 0 rem(16) rem(6) rgba($color-black, 0.3);
      z-index: 1;
    }
  }
  .c--ribbon-carousel-slide {
    height: 100%;

    a {
      display: inline-block;

      @include mq($bp1024) {
        text-decoration: none;

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }

  .text-container {
    @include padding(rem(17) rem(20) rem(40));

    @include mq($bp768) {
      @include padding(null cols(1.7, 18) rem(73));
    }

    @include mq($bp1024) {
      @include padding(null cols(1, 11));
    }
  }

  .f--section-title {
    h2 {
      @extend %font-fira-sans-extra-condensed-semibold;
      @include margin(null null rem(8));
      @include font-size(24, 28);
      letter-spacing: rem(-.5);
      color: $color-black;

      @include mq($bp768) {
        @include margin(null null rem(6));
      }
    }
  }

  .f--sub-title {
    h3 {
      @extend %font-fira-sans-extra-condensed-medium;
      @include font-size(20, 20);
      @include margin(null null rem(14));
      color: $color-black;
      text-transform: uppercase;

      @include mq($bp768) {
        @include margin(null null rem(10));
      }
    }
  }

  .f--description {
    color: $color-black;
    letter-spacing: rem(-.7);

    @include mq($bp768max) {
      @include font-size(14, 18);
    }
  }

  .f--link {
    @include margin(rem(10) null null);
    text-align: right;

    @include mq($bp768) {
      @include margin(rem(45) null null);
    }

    a {
      @extend %font-fira-sans-bold;
      @include font-size(18, 18);
      text-decoration: underline;
      text-transform: uppercase;

      @include hover {
        text-decoration: none!important;
      }

      @include mq($bp768) {
        @include font-size(20, 20); 
        text-decoration: none;

        @include hover {
          text-decoration: underline!important;
        }
      }

      svg {
        @include margin(null null null rem(8));
        display: inline-block;
        width: rem(8);
        height: rem(14);
        transition: transform $transition-duration-fast $transition-easing-default;

        @include mq($bp768) {
          @include margin(null null null rem(10));
          width: rem(14);
          height: rem(24);
          vertical-align: top;
        }

        path {
          fill: $color-black;
        }
      }

      @include hover {
        text-decoration: underline;

        svg {
          transform: translate3d(rem(3), 0, 0);
          transition: transform $transition-duration-fast $transition-easing-default;
        }
      }
    }
  }
}
