.cc--news-listing-item {
  .c--news-listing-item {
    overflow: hidden;

    @include mq($bp768max) {
      display: flex;
      flex-direction: column-reverse;
    }
    .image-container {
      a {
          display:block;
       }
    }
  }

  .f--category {
    @include margin(null null rem(10));

    @include mq($bp768) {
      @include margin(null null rem(15));
    }

    @include mq($bp1024) {
      @include margin(null null rem(10));
    }
  }

  .f--cta-title {
    @include margin(null null rem(10));

    @include mq($bp768) {
      @include margin(null null rem(15));
    }

    @include mq($bp1024) {
      @include margin(null null rem(11));
    }

    h3 {
      @include font-size(24, 30);

      @include mq($bp1024) {
        @include font-size(30, 36);
      }

      a {
        color: $color-sherpa-blue;
        text-decoration: none;

        @include hover {
          color: $color-sherpa-blue;
          text-decoration: underline;
        }
      }
    }
  }

  .f--wysiwyg {
    @include margin(null null rem(11));

    @include mq($bp768) {
      @include margin(null null rem(22));
    }

    @include mq($bp1024) {
      @include margin(null null rem(11));
    }

    p {
      margin-bottom: 0;
    }
  }

  .f--date-string {
    @extend %font-fira-sans-extra-condensed-semibold;
    @include font-size(16, 16);

    @include mq($bp768) {
      @include font-size(18, 18);
    }
  }

  .f--link {
    @include margin(rem(12) null null);

    @include mq($bp768) {
      @include margin(rem(24) null null);
    }

    .link {
      @extend %font-fira-sans-extra-condensed-regular;
      @include padding(null rem(18) null null);
      @include font-size(16, 16);
      background: url('../images/icons-usf/external_link.svg') no-repeat right center;
      background-size: rem(11);
      text-decoration: none;

      @include mq($bp768) {
        @include font-size(20, 24);
      }

      @include hover {
        text-decoration: underline;
      }
    }
  }

  .image-container {
    @include mq($bp768max) {
      @include margin(rem(16) null null);
    }

    @include mq($bp768) {
      @include padding(rem(4) rem(4) null cols(2, 27));
      width: cols(14, 27);
      float: right;
    }

    @include mq($bp1024) {
      @include padding(null null null cols(2, 32));
      width: cols(14, 32);
    }
  }
}
