@mixin button($secondary: false, $green: false, $red: false, $white: false, $black: false, $yellow: false) {
  @extend %font-fira-sans-bold;
  @include font-size(18, 18);
  @include padding(null rem(32));
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  width: 100%;
  min-height: rem(50);
  border: rem(1) solid $button-default-border;
  border-radius: 0;
  color: $color-black;
  background-color: transparent;
  text-transform: uppercase;
  transition: background-color $transition-duration-fast $transition-easing-default;
  appearance: none;
  outline-offset: 0;

  @include mq($bp768) {
    width: auto;
    min-width: rem(200);
  }

  &:focus {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-default-hover-bg;
    color: $button-default-hover-color;
    border-color: $button-default-hover-border;
    text-decoration: none;
  }

  @include hover {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-default-hover-bg;
    color: $button-default-hover-color;
    border-width: rem(5);
    text-decoration: none;
  }


  // with #FDBB30 border
  @if $secondary {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-secondary-bg;
    color: $button-secondary-color;
    border-width: rem(2);
    border-color: $button-secondary-border;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 0 $color-supernova;

    &:focus {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-secondary-hover-bg;
      color: $button-secondary-hover-color;
      border-color: $button-secondary-hover-border;
      text-decoration: none;
      box-shadow: inset 0 0 0 rem(2) $color-supernova;
    }

    @include hover {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-secondary-hover-bg;
      color: $button-secondary-hover-color;
      border-color: $button-secondary-hover-border;
      border-width: rem(2);
      text-decoration: none;
      box-shadow: inset 0 0 0 rem(3) $color-supernova;
    }
  }

  @if $green {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-secondary-bg;
    color: $button-secondary-color;
    border-width: rem(2);
    border-color: $color-atlantis;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 0 $color-atlantis;

    &:focus {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-secondary-hover-bg;
      color: $button-secondary-hover-color;
      border-color: $color-atlantis;
      text-decoration: none;
      box-shadow: inset 0 0 0 rem(2) $color-atlantis;
    }

    @include hover {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-secondary-hover-bg;
      color: $button-secondary-hover-color;
      border-color: $color-atlantis;
      border-width: rem(2);
      text-decoration: none;
      box-shadow: inset 0 0 0 rem(3) $color-atlantis;
    }
  }

  @if $white {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-primary-bg;
    color: $button-primary-color;
    border: 2px solid $button-primary-color;

    &:focus {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-primary-bg;
      color: $button-primary-hover-color;
      text-decoration: none;
      border-color: $button-primary-color;
      border-width: 5px;
    }

    @include hover {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $button-primary-bg;
      color: $button-primary-hover-color;
      text-decoration: none;
      border-color: $button-primary-color;
      border-width: 5px;
    }
  }

  @if $black {
    transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
    background-color: $button-primary-bg;
    color: $color-black;
    border: rem(2) solid $color-black;

    &:focus {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $color-black;
      color: $color-white;
      text-decoration: none;
    }

    @include hover {
      transition: background-color $transition-duration-fast $transition-easing-default, color $transition-duration-fast $transition-easing-default;
      background-color: $color-black;
      color: $color-white;
      text-decoration: none;
    }
  }
}

@mixin button-alt($reversed: false) {
  @if $reversed {
    @include padding(null 0);
    position: relative;
    display: inline-block;
    text-decoration: none;
    width: auto;
    height: auto;
    min-width: auto;
    border: 0;
    border-radius: 0;
    color: $color-secondary;
    background-color: transparent;

    &:focus {
      background-color: transparent;
      color: $color-secondary;
    }

    @include hover {
      background-color: transparent;
      color: $color-secondary;
    }
  }
  @else {
    @extend %font-fira-sans-extra-condensed-bold;
    @include font-size(14, 20);
    color: $color-secondary;
    border-bottom: 2px solid $color-orange;
    text-decoration: none;

    &:focus {
      transition: border-color $transition-duration-default $transition-easing-default;
      color: $color-secondary;
      border-color: $color-secondary;
    }

    @include hover {
      transition: border-color $transition-duration-default $transition-easing-default;
      color: $color-secondary;
      border-color: $color-secondary;
    }
  }
}
