.cc--title-and-link-list {
  @include maxwidth;

  .c--title-and-link-list {
    @include padding(rem(30) cols(3, 40));

    @include mq($bp768) {
      @include padding(rem(40) cols(2));
      display: flex;
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .header-container {
      @include mq($bp768) {
        @include padding(null cols(2, 22) null null);
        display: flex;
        align-items: center;
        width: cols(22, 44);
      }

      @include mq($bp1024) {
        @include padding(null cols(2, 27) null null);
        width: cols(27, 44);
      }

      .f--section-title {
        width: 100%;

        h2 {
          @include font-size(30, 30);
          text-align: left;
          text-transform: none;

          @include mq($bp768) {
            @include font-size(36, 36);
            @include margin(null null 0);
          }

          @include mq($bp1024) {
            @include font-size(40, 53);
          }

          strong {
            @include font-size(48, 48);
            display: block;
            color: $color-grey;
            text-transform: uppercase;

            @include mq($bp1024) {
              @include font-size(60, 60);
            }
          }
        }
      }
    }

    .link-container {
      @include mq($bp768) {
        display: flex;
        align-items: center;
        width: cols(22, 44);
      }

      @include mq($bp1024) {
        width: cols(17, 44);
      }

      ul {
        @include no-bullet;
        width: 100%;

        li {
          border-bottom: rem(1) solid rgba($color-black, .2);

          &:first-child {
            a {
              @include padding(0 null null);
            }
          }

          a {
            @extend %font-fira-sans-bold;
            @include font-size(14, 14);
            @include padding(rem(20) null);
            display: block;
            text-decoration: none;
            text-transform: uppercase;

            @include mq($bp768) {
              @include padding(rem(25) null);
            }

            @include mq($bp1024) {
              @include font-size(16, 26);
              @include padding(rem(20) null);
            }

            &::after {
              @extend %link-alt;
            }
          }
        }
      }
    }
  }

  &.text-right {
    .c--title-and-link-list {
      @include mq($bp768) {
        flex-direction: row-reverse;
      }

      .header-container {
        @include mq($bp768) {
          @include padding(null 0 null cols(2, 22));
        }

        @include mq($bp1024) {
          @include padding(null 0 null cols(2, 27));
        }

        .f--section-title {
          h2 {
            @include mq($bp768) {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
