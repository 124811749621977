.cc--authors-card {
  .c--authors-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .f--cta-title {
    h3 {
      @include margin(rem(15) null null);
      @include font-size(18, 26);
      text-align: center;

      @include mq($bp768) {
        @include font-size(20, 30);
      }

      @include mq($bp1024) {
        @include margin(rem(20) null null);
      }
    }
  }
}
