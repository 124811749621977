*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  @extend %font-ibm-plex-serif-regular;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.1;
}

p {
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  margin-top: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

ul {
  list-style-type: disc;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  path,
  polygon,
  line,
  rect,
  ellipse,
  circle {
    transition: fill $transition-duration-fast $transition-easing-default;

    @include hover {
      transition: fill $transition-duration-fast $transition-easing-default;
    }
  }
}

nav {
  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

dl {
  margin-top: 0;
}

dd {
  margin: 0;
}

figure {
  margin: 0 0 rem(20);

  img {
    width: 100%;
  }

  figcaption {
    @include margin(null null null null);
    @include font-size(12, 16);
    color: $color-secondary;
    letter-spacing: rem(.3);
    text-transform: uppercase;
  }

  &.left {
    @include mq($bp768) {
      @include margin(0 cols(2) rem(20) cols(-5));
      width: 60%;
      float: left;
    }
  }

  &.right {
    @include mq($bp768) {
      @include margin(0 cols(-5) rem(20) cols(2));
      width: 60%;
      float: right;
    }
  }
}

address {
  font-style: normal;
}

form {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

textarea {
  resize: vertical;
}

button {
  @extend %font-ibm-plex-serif-regular;
  padding: 0;
  border: 0;
  background-color: transparent;
}

button,
[type='button'],
[type='reset'],
[type='submit'],
.button-reset {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

iframe {
  border: 0;
}

section {
  &.two-cols {
    @include margin(rem(40) null);

    @include mq($bp768) {
      column-gap: rem(40);
      column-count: 2;
    }

    img {
      margin-bottom: rem(15);
      float: left;
    }
  }
}

[tabindex='-1']:focus {
  outline: none !important;
}



/* ==========================================================================
   Print styles.
   https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
   ========================================================================== */
@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

