/*  Visually hide text within an element
*   Used mostly for accessibility reasonse
*
*   No Params
*
*   Example Usage:
*
*     button {
*       span {
*         @include visuallyhidden;
*       }
*     }
*
*/
@mixin visuallyhidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
  color: $color-black;	
	background-color:$color-white;
}
