$radius: 0;

.cc--video {
  @include maxwidth;

  .c--video {
    @include padding(null cols(3));

    @include mq($bp768) {
      @include padding(null cols(2));
    }

    .video-wrapper {
      @include padding(null null 56.25%);
      position: relative;
      height: 0;
      overflow: hidden;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius;
    }
  }
}
