// text color
$text-color: $color-white;
// gradient overlay
$overlay-color: rgba($color-black, 0.5);
// turn overlay on and off
$background-color-fallback: $color-primary;

.cc--article-hero-featured {
  @include margin(null null rem(30));
  position: relative;

  @include mq($bp1024) {
    @include margin(null null rem(50));
  }

  .c--article-hero-featured {
    position: relative;
  }

  .image-wrapper {
    img {
      width: 100%;
      height: 100%;
      background-color: $background-color-fallback;
      object-fit: cover;
      aspect-ratio: 16 / 9;

      @include mq($bp768) {
        aspect-ratio: 8 / 7;
      }

      @include mq($bp1024) {
        aspect-ratio: 16 / 9;
      }
    }

    @supports not (aspect-ratio: 16 / 9) { // fallback for aspect ratio
      padding-top: 56.25%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }

      @include mq($bp768) {
        padding-top: 87.5%;
      }

      @include mq($bp1024) {
        padding-top: 56.25%;
      }
    }
  }

  .text-wrapper {
    @include padding(null rem(20));
    @include maxwidth;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top:20px;
    //top margine added here, for reference

    @include mq($bp768) {
      @include padding(null cols(2) rem(30));
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 70%;

      &::before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 60%);
        content: '';
      }
    }

    @include mq($bp1024) {
      @include padding(null cols(10) rem(35) cols(2));
    }

    .f--category {
      @include margin(rem(20) null rem(10));
      @extend %font-fira-sans-extra-condensed-semibold;
      @include font-size(14, 14);
      position: relative;
      color: $color-sherpa-blue;

      @include mq($bp768) {
        @include margin(0 null rem(25));
        color: $color-white;
      }

      p {
        @extend %font-fira-sans-extra-condensed-semibold;
        @include font-size(14, 14);
        color: $color-sherpa-blue;

        @include mq($bp768) {
          @include font-size(16, 16);
          color: $color-white;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .f--page-title {
      @include margin(null null rem(10));
      position: relative;

      @include mq($bp768) {
        @include margin(0 null rem(18));
      }

      @include mq($bp1024) {
        @include margin(null null rem(20));
      }

      h1 {
        @include font-size(30, 36);
        color: $color-sherpa-blue;
        letter-spacing: 0;
        text-transform: none;

        @include mq($bp768) {
          @include font-size(60, 72);
          color: $color-white;
          letter-spacing: rem(.5);
        }
      }
    }

    .f--authored-date {
      &,
      .text-container {
        display: flex;
      }

      .text-container {
        p {
          @extend %font-fira-sans-extra-condensed-regular;
          @include font-size(12, 12);
          display: flex;
          align-items: center;
          color: $color-sherpa-blue;

          @include mq($bp768) {
            @include font-size(16, 23);
            display: block;
            color: $color-white;
          }

          strong {
            text-transform: uppercase;
          }
        }

        p::after {
          display: none;
        }

        time {
          @extend %font-fira-sans-extra-condensed-regular;
          @include font-size(12, 12);
          display: flex;
          align-items: center;
          color: $color-sherpa-blue;

          @include mq($bp768) {
            @include font-size(16, 23);
            color: $color-white;
          }
        }

        .separator + time {
          &::before {
            @include margin(null rem(10));
            display: inline-block;
            height: rem(10);
            border-left: rem(1) solid $color-supernova;
            content: '';

            @include mq($bp768) {
              @include margin(null rem(20));
              height: rem(20);
            }
          }
        }
      }
    }

    .f--description {
      @include margin(null null rem(20));
      @extend %font-fira-sans-extra-condensed-regular;
      @include font-size(18, 24);
      position: relative;
      color: $color-sherpa-blue;

      @include mq($bp768) {
        @include margin(null null rem(25));
        @include font-size(24, 24);
        color: $color-white;
      }

      p {
        @extend %font-fira-sans-extra-condensed-regular;
        @include font-size(18, 24);
        color: $color-sherpa-blue;

        @include mq($bp768) {
          @include font-size(24, 30);
          color: $color-white;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .f--authored-date {
      justify-content: flex-start;
      position: relative;
      z-index: 2;

      @include mq($bp768) {
        @include margin(null null rem(10));
      }

      @include mq($bp1024) {
        @include margin(null null rem(5));
      }

      span {
        @include font-size(12, 12);

        @include mq($bp768) {
          @include font-size(16, 16);
        }
      }
    }
  }
}