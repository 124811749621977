/*
  Maintain ratio mixin.
  @param {List} [$ratio] Ratio the element needs to maintain. A 16:9 ratio would look like this:
    .element { @include maintain-ratio(16 9); }
*/
@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}
