$sidebar-bg-color: $color-light-grey;
$radius: 0;

.cc--event-detail {
  height: 100%;

  @include mq($bp768max) {
    @include padding(null cols(3));
    @include margin(null null rem(30) null);
  }

  @include mq($bp768) {
    @include padding(null cols(2, 13));
    border-radius: 0 $radius $radius 0;
  }

  .text-container {
    @include padding(null null rem(30));

    @include mq($bp768) {
      @include padding(null null rem(25));
    }

    @include mq($bp1024) {
      @include padding(null null rem(44));
    }

    .date-wrapper {
      @include margin(null null rem(15));
      @include padding(rem(10));
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-caribbean-green;
      @include mq($bp768) {
        @include margin(null null rem(25));
      }

      .f--date-string {
        @extend %font-fira-sans-extra-condensed-semibold;
        @include font-size(14, 14);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $date-color;
        text-transform: uppercase;
        justify-items: center;

        @include mq($bp768) {
          @include font-size(20, 24);
        }

        span {
          @extend %font-fira-sans-extra-condensed-regular;
          @include margin(null null rem(2) null);
          @include font-size(36, 36);
          display: block;

          @include mq($bp768) {
            @include margin(null null rem(5) null);
            @include font-size(60, 60);
          }

          @include mq($bp1024) {
            @include margin(null null 0);
          }
        }
      }
    }

    .f--time-string {
      @extend %font-fira-sans-extra-condensed-regular;
      @include margin(null null rem(24));
      @include padding(null null rem(24));
      @include font-size(18, 18);
      border-bottom: rem(1) solid $color-supernova;
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(20, 30);
      }
    }

    .location-wrapper {
      @include padding(null null rem(24));
      @include margin(null null rem(24));
      border-bottom: rem(1) solid $color-supernova;

      .f--link {
        @extend %font-ibm-plex-serif-regular;
        @include margin(rem(14) null null null);
        @include font-size(14, 18);

        @include mq($bp768) {
          @include font-size(16, 24);
        }

        a {
          color: $color-sherpa-blue;
          text-decoration: none;

          @include hover {
            text-decoration: underline;
          }

          svg {
            @include margin(null rem(10) null null);
            vertical-align: middle;
          }
        }
      }
    }

    .f--text {
      @extend %font-fira-sans-extra-condensed-semibold;
      @include font-size(18, 18);

      @include mq($bp768) {
        @include font-size(20, 30);
      }

      p {
        @extend %font-fira-sans-extra-condensed-semibold;
        @include font-size(18, 18);

        @include mq($bp768) {
          @include font-size(20, 30);
        }

        a {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    .f--description {
      @extend %font-ibm-plex-serif-regular;
      @include font-size(14, 18);

      @include mq($bp1024) {
        @include font-size(16, 24);
      }

      a {
        color: $color-sherpa-blue;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;

        &:hover {
          color: $color-sherpa-blue;
        }
      }
    }
  }

  .links-container {
    @include margin(rem(30) null null);

    @include mq($bp768) {
      @include margin(rem(25) null null);
    }

    @include mq($bp1024) {
      @include margin(rem(30) null null);
    }

    .f--link {
      @include margin(null null rem(30));

      .link {
        @include button($secondary: true);
        width: 100%;
        min-width: auto;

        @include mq($bp768) {
          width: 100%;
          min-width: auto;
        }
      }

      &:last-child {
        @include margin(null null 0);
      }
    }
  }

  .cc--social-share .c--social-share {
    a {
      width: rem(45);
      height: rem(45);
    }
  }
}

.hide-for-mobile {
  @include mq($bp768max) {
    display: none;
  }
}
