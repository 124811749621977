.f--ambient-video {
  width: 100%;
  display:block !important;
  @include mq($bp768max) {
    @include padding(null null percentage(9/16) null);
  }

  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include mq($bp1024) {
      // ie11
      @media screen and (-ms-high-contrast: none) {
        height: auto;
        object-fit: none;
      }

      // edge
      @supports (-ms-ime-align: auto) {
        height: auto;
        object-fit: none;
      }
    }
  }
}
