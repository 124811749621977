.cc--branding-footer {
  @include mq($bp768max) {
    @include padding(null cols(1.5, 39) null null);
    flex-basis: 50%;
  }

  a {
    display: block;
    width: 100%;
    text-decoration: none;

    @include mq($bp768max) {
      @include margin(null auto);
    }
  }

  svg {
    width: 100%;
  }

  &#block-footer-sitebranding-schoolofnursing,
  &#block-footer-sitebranding-schooloflaw,
  &#block-footer-sitebranding-collegeofartsandsciences,
  &#block-footer-sitebranding-schoolofmanagement,
  &#block-footer-sitebranding-gradschoolofmanagement,
  &#block-footer-sitebranding-schoolofeducation {
    @include mq($bp768max) {
      @include margin(null null rem(20));
      padding: 0;
      flex-basis: 100%;

      ~ .cc--footer-contact {
        flex-basis: 100%;
        padding: 0;
      }
    }
  }

  &#block-footer-sitebranding-schoolofnursing,
  &#block-footer-sitebranding-schooloflaw,
  &#block-footer-sitebranding-collegeofartsandsciences,
  &#block-footer-sitebranding-schoolofmanagement,
  &#block-footer-sitebranding-gradschoolofmanagement,
  &#block-footer-sitebranding-schoolofeducation {

    .school-college-logo-footer-wrapper,
    .general-usf-logo-footer {
      display: inline;
      @include mq($bp1024) { 
        display: block;
      }
    }
    a {
      &.school-college-logo-footer {
        position: relative;
        display:inline;
        left: rem(-2);

        @include mq($bp1024) {
          left: rem(145);
          top: -37px;
          width: rem(170);
        }
      }
      svg {
        width: 48.9%;

        @include mq($bp1024) {
          width: rem(144);
        }
      }
    }
  }

  &#block-footer-sitebranding-marketing,
  &#block-footer-sitebranding-schoolofnursing-marketing,
  &#block-footer-sitebranding-schooloflaw-marketing,
  &#block-footer-sitebranding-collegeofartsandsciences-marketing,
  &#block-footer-sitebranding-schoolofmanagement-marketing,
  &#block-footer-sitebranding-schoolofeducation-marketing {

    .school-college-logo-footer-wrapper,
    .general-usf-logo-footer {
      display: inline;
      position: absolute;
    }
    a {
      &.school-college-logo-footer {
        position: absolute;
        display:inline;
        top: 0;
        left: rem(170);
      }
      svg {
          width: rem(170);
          @include mq($bp768max) {
            left: rem(170);
          }
      }
      &.marketing-logo {
        svg {
            width: rem(240);
        }
      }
    }
  }
  &#block-footer-sitebranding-marketing {
    position: relative;
    margin-bottom: -2rem;
  }
}



