.cc--branding-header {
  .c--branding-header {
    @include padding(rem(13) null rem(13) rem(20));
    height: rem(80);

    @include mq($bp1024max) {
      display: flex;
      align-items: center;
    }

    @include mq($bp768) {
      @include padding(rem(19) null rem(19) cols(1, 24));
      height: rem(120);
    }

    @include mq($bp1024) {
      @include padding(rem(40) null 0 cols(2, 20));
      height: rem(150);
    }
  }

  &#block-sitebranding-collegeofartsandsciences,
  &#block-sitebranding-magazine,
  &#block-sitebranding-schoolofeducation,
  &#block-sitebranding-schooloflaw,
  &#block-sitebranding-schoolofmanagement,
  &#block-sitebranding-gradschoolofmanagement,
  &#block-sitebranding-schoolofnursing {
    @include mq($bp768max) {
      a {
        max-width: rem(260);
      }
    }

    @include mq($bp1024) {
      svg {
        path[fill='#00543C'],
        path[fill='#8A8C8E'],
        rect {
          fill: $color-white;
        }
      }
    }
  }

  &#block-sitebranding-collegeofartsandsciences,
  &#block-sitebranding-collegeofartsandsciencesmegamenu,
  &#block-sitebranding-magazine,
  &#block-sitebranding-magazinemegamenu,
  &#block-sitebranding-schoolofeducation,
  &#block-sitebranding-schoolofeducationmegamenu,
  &#block-sitebranding-schooloflaw,
  &#block-sitebranding-schooloflawmegamenu,
  &#block-sitebranding-schoolofmanagement,
  &#block-sitebranding-gradschoolofmanagement,
  &#block-sitebranding-schoolofmanagementmegamenu,
  &#block-sitebranding-gradschoolofmanagementmegamenu,
  &#block-sitebranding-schoolofnursing,
  &#block-sitebranding-schoolofnursingmegamenu {
      a {
      &.school-college-logo {
        position: relative;
        @include mq($bp1024) {    
          left: 13rem;
          top: -44px;
        }
        @include mq($bp1200) {
          left:15.875rem;
          top: -52px;
        }
      }

      svg {
        width: rem(132);

        @include mq($bp768) {
          width: rem(250);
        }

        @include mq($bp1024) {
          width: rem(208);
        }

        @include mq($bp1200) {
          width: rem(250);
        }
      }
    }
  }

  a {
    display: block;
    font-size: 0;
    line-height: 1;
    text-decoration: none;

    svg {
      width: rem(240);
      height: auto;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: rem(30);
      }

      @media screen and (-ms-high-contrast: active) and (min-width: 1024px), (-ms-high-contrast: none) and (min-width: 1024px) {
        height: rem(44);
      }

      @include mq($bp1024max) {
        g[fill='#fff'] {
          path {
            fill: $color-sherpa-blue;
          }
        }
      }

      @include mq($bp768) {
        width: rem(368);
      }

      @include mq($bp1024) {
        width: rem(300);
        max-width: 100%;
      }
    }  
  }

  a.magazine-logo {
    svg {
      width: rem(208);

      @include mq($bp768) {
        width: rem(304);
      }

      @include mq($bp1024) {
        width: rem(252);
      }

      .logo-magazine-usf {
        stroke-width: 2px;
      }
      
      @include mq($bp1024max) {
        .logo-magazine-usf {
          stroke:$color-aluminium;
        }
        .logo-magazine-text {
          fill:$color-dove-gray;
        }
      }
    }
  }
}


